"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { useDispatch } from "react-redux";

import { useGoogleReCaptcha } from "react-google-recaptcha-hook";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import Table from "../Table/Table";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";

import HibtService from "@/services/hibtService";

import { setMoreLikeThisModalInfo } from "../../app/GlobalRedux/Features/moreLikeThisModalSlice";
import { AppDispatch, useAppSelector } from "../../app/GlobalRedux/store";
import { ListTypeId, SearchResultItem, SearchType } from "@/types";

import LoadingState from "../states/LoadingState";
import CheckBox from "../CheckBox/CheckBox";
import styles from "./MoreLikeThisModal.module.scss";
import { setSnackBar } from "../../app/GlobalRedux/Features/snackBarSlice";
import ErrorState from "../states/ErrorState/ErrorState";
import Logging from "@/utils/Logging";
import EmptyState from "../states/EmptyState/EmptyState";
import Button from "../Button/Button";
import { useRouter } from "next/navigation";
import { setCurrentSearch } from "@/app/GlobalRedux/Features/searchSlice";
import mixpanel from "mixpanel-browser";
import { getSearchUrl } from "@/utils/utils";

interface MoreLikeThisModalProps {
  image_url: string;
  caption: string;
}

const MoreLikeThisModal: React.FC<MoreLikeThisModalProps> = ({
  image_url,
  caption,
}) => {
  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    process.env.NEXT_PUBLIC_REACT_GACAPTV3_SITE_KEY || "",
    { hide: true }
  );
  const isModalOpen = useAppSelector((state) => state.moreLikeThis.isOpen);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [urlCopied, setUrlCopied] = useState(false);

  const [data, setData] = useState<SearchResultItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<number>(0);
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const user = useAppSelector((state) => state.auth.user);
  const lists = useAppSelector((state) => state.auth.lists);
  const { doNotTrainList } = useAppSelector((state) => state.doNotTrainData);

  const [doNotTrainListData, setDoNotTrainListData] = useState(doNotTrainList);

  const modalRef = useRef<HTMLDivElement>(null);

  const loadMoreLikeThisData = async () => {
    modalRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setIsLoading(true);

    try {
      const captchaKey = await executeGoogleReCaptcha("submit");
      const { records } = await HibtService.searchMoreLikeThis({
        image_url: image_url || undefined,
        CaptchaResponse: captchaKey,
      });

      // Filter out items that are in the "Do Not Train" list
      const filteredData = records.filter(
        (item) => !doNotTrainListData.map(({ url }) => url).includes(item.url)
      );

      setData(filteredData);
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsLoading(false);

      Logging.networkError("API Error - searchMoreLikeThis", {
        pageName: "MoreLikeThisModal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });

      dispatch(
        setSnackBar({
          open: true,
          message: `An error occurred: ${error.message}`,
          variant: "error",
        })
      );
      setIsError(true);
    }
  };

  useEffect(() => {
    loadMoreLikeThisData();
  }, [image_url]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCheckedItems(data.filter(({ isChecked }) => isChecked).length);
  }, [data]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch(setMoreLikeThisModalInfo({ isOpen: false }));
      }
    };

    if (isModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOptingOutItems = async () => {
    if (!user || !lists) return;
    setIsLoading(true);

    const selectedItems = data.filter(({ isChecked }) => isChecked);

    const doNotTrainList = lists.find(
      ({ type_id }) => type_id === ListTypeId.DO_NOT_TRAIN
    );

    const dataToSend = selectedItems.map(({ id, url, caption }) => ({
      id,
      url,
      caption,
      type_id: ListTypeId.DO_NOT_TRAIN,
      list_id: doNotTrainList?.id || 0,
    }));

    try {
      const optOutResponse =
        await HibtService.insertMultipleObjectsToSelections(
          user.id_token,
          dataToSend
        );

      if (optOutResponse.status === 200) {
        // Get IDs of items opted out
        const optedOutIds = selectedItems.map((item) => item.id);

        // Update local "Do Not Train" list state
        setDoNotTrainListData((prevList) => [...prevList, ...selectedItems]);

        const updatedSearchData = data.filter(
          (item) => !optedOutIds.includes(item.id)
        );

        setData(updatedSearchData);

        await dispatch(
          setSnackBar({
            open: true,
            message: `${dataToSend.length} ${
              dataToSend.length === 1 ? "item" : "items"
            } add to your`,
            variant: "doNotTrain",
          })
        );
      } else {
        dispatch(
          setSnackBar({
            open: true,
            message: `Failed to add ${dataToSend.length} ${
              dataToSend.length === 1 ? "item" : "items"
            } to your`,
            variant: "doNotTrainError",
          })
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("API Error - handle opting out items", {
        pageName: "MoreLikeThisModal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });

      dispatch(
        setSnackBar({
          open: true,
          message: `An error occurred: ${error.message}`,
          variant: "error",
        })
      );
    }

    setIsLoading(false);
  };

  const handleCheckBoxClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { checked } = e.target;

    const newSelectionArray = data.map((searchResult) =>
      searchResult.id === id
        ? { ...searchResult, isChecked: checked }
        : searchResult
    );

    setData(newSelectionArray);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number
  ) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).checked = true;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = data.map((searchResult) =>
        searchResult.id === id
          ? { ...searchResult, isChecked: checked }
          : searchResult
      );

      setData(newSelectionArray);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      (e.target as HTMLInputElement).checked = false;

      const { checked } = e.target as HTMLInputElement;

      const newSelectionArray = data.map((searchResult) =>
        searchResult.id === id
          ? { ...searchResult, isChecked: checked }
          : searchResult
      );

      setData(newSelectionArray);
    }
  };

  const handleOnSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const selectAllItems = data.map((searchResult) => ({
      ...searchResult,
      isChecked: checked,
    }));

    setData(selectAllItems);
  };

  const handleKeyDownSelectAll = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).checked = true;

      const { checked } = e.target as HTMLInputElement;

      const selectAllItems = data.map((searchResult) => ({
        ...searchResult,
        isChecked: checked,
      }));

      setData(selectAllItems);
    }
  };

  const handleShare = () => {
    if (window && typeof window !== "undefined") {
      const shareUrl = `${window.location.origin}/share?url=${image_url}`;

      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          setUrlCopied(true);
        })
        .catch((err) => {
          Logging.event("Clipboard Error", {
            page: "MoreLikeThisModal",
            err,
          });
        });
    }

    setTimeout(() => {
      setUrlCopied(false);
    }, 2000);
  };

  const handleSearchForMore = () => {
    dispatch(setMoreLikeThisModalInfo({ isOpen: false }));

    const shareUrl = `${window.location.origin}/share?url=${image_url}`;

    mixpanel.track(`Search more from more like this modal`, {
      type: SearchType.IMAGE,
    });

    const route = shareUrl;
    router.push(route);
  };

  if (!image_url) {
    return null;
  }

  const host = new URL(image_url).host;

  if (!isModalOpen) {
    return null;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <ClickAwayListener
        onClickAway={() =>
          dispatch(setMoreLikeThisModalInfo({ isOpen: false }))
        }
      >
        <div className={styles.content}>
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              <IconButton
                className={styles.closeButton}
                onClick={() =>
                  dispatch(setMoreLikeThisModalInfo({ isOpen: false }))
                }
              >
                <CloseIcon />
              </IconButton>
              <div className={styles.imageWrapper}>
                <img
                  src={image_url}
                  alt={caption}
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    overflowX: "hidden",
                    height: "100%"
                  }}
                />
              </div>

              <div className={styles.infoWrapper}>
                <h2>{caption}</h2>
                <div className={styles.sourceWrapper}>
                  <div className={styles.source}>{host}</div>

                  <IconButton href={image_url} target="_blank">
                    <OpenInNewIcon fontSize="small" sx={{ color: "gray" }} />
                  </IconButton>
                </div>
                <Tooltip title={urlCopied ? "Copied" : "Share"}>
                  <IconButton
                    onClick={() => {
                      handleShare();
                    }}
                  >
                    <ShareIcon sx={{ color: "#000" }} />
                  </IconButton>
                </Tooltip>
              </div>

              <div className={styles.divider}>
                <span className={styles.mltBadge}>More like this</span>
              </div>

              <div className={styles.tableAndControlsWrapper}>
                <div className={styles.controlsWrapper}>
                  <div className={styles.controlsContent}>
                    <div className={styles.actionsWrapper}>
                      <CheckBox
                        isModal
                        isSelectAllCheckbox
                        handleOnSelectAllClick={handleOnSelectAllClick}
                        handleKeyDownSelectAll={handleKeyDownSelectAll}
                        labelText={
                          data.length > 1
                            ? `Select all ${data.length} items`
                            : `Select ${data.length} item`
                        }
                      />
                    </div>
                    {/* <div>{data.length} similar items</div> */}
                    <div className={styles.doNotTrainButtonWrapper}>
                      Mark {checkedItems}
                      {`${checkedItems === 1 ? " item " : " items "}`} as:
                      <Button
                        disabled={checkedItems === 0}
                        onClick={handleOptingOutItems}
                        className={styles.doNotTrainButton}
                      >
                        Do Not Train
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={styles.tableWrapper}>
                  {data.length > 0 && !isLoading ? (
                    <Table
                      dataToDisplay={data}
                      handleCheckBoxClick={handleCheckBoxClick}
                      handleKeyDown={handleKeyDown}
                    />
                  ) : (
                    <EmptyState>
                      We were unable to find any similar works for this item.
                    </EmptyState>
                  )}
                  <div>
                    <Button onClick={handleSearchForMore}>
                      Search for more
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default MoreLikeThisModal;
